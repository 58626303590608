<template lang="pug">
  div
    chart(v-if="report" :options="mergedOptions" :series="series" height="350px")
    div(v-if="!report")
      button-spinner
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import _ from 'lodash';

export default {
  components: {
    chart: VueApexCharts
  },

  props: {
    options: {
      type: Object,
      default:() => {
        return {};
      }
    },
    report: {
      type: Object,
      default: () => {
        return { duration: 0, view: { raw: [], percent: [] }, session: { garment: { sensors: [] } } };
      }
    }
  },

  data() {
    return {
      baseOptions: {
        dataLabels: {
          enabled: false
        },

        "markers": {
          "size": 0
        },

        stroke: {
          width: 2
        },

        "fill": {
          "type": "gradient",
          "gradient": {
            "shadeIntensity": 1,
            "inverseColors": false,
            "opacityFrom": 0.5,
            "opacityTo": 0,
            "stops": [0, 90, 100]
          }
        }
      }
    }
  },

  methods: {
    fetchSeriesData(type = 'raw', includeLegend = true) {
      const series = [];

      this.report.session.garment.sensors.forEach((sensor) => {
        let data = this.report.view[type][sensor.uid.toLowerCase()];

        if(!_.isArray(data)) {
          data = [ data ];
        }

        const current = { data: data };

        if(true === includeLegend) {
          current['name'] = sensor.name;
        }

        series.push(current);
      });

      return series;
    }
  },

  computed: {
    mergedOptions() {
      return {};
    },
    series() {
      return [];
    }
  }
}
</script>
