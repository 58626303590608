<style lang="scss">
  .page {
    break-after: page;
  }
</style>

<template lang="pug">
  div
    .card(v-if="!report")
      .card-body.text-center
        spinner

    div(v-else)
      .card
        .card-body
          button(type="button" class="btn btn-primary float-right" @click="download")
            span(v-if="!generating")
              fa(icon="file-pdf")
              = ' '
              | {{ $t('download.button.label') }}

            span(v-else)
              button-spinner(color="#FFFFFF")

      div
        .card
          .card-body
            .row
              .col-md-6
                h5 {{ $t('report.session.caption') }}
                table.table.table-sm#data-session
                  tbody
                    tr
                      td
                        b {{ $t('report.session.name') }}
                      td {{ session.name }}

                    tr
                      td
                        b {{ $t('report.session.date') }}

                      td {{ session.createdAt | datetime }}

                    tr
                      td
                        b {{ $t('report.session.activity') }}

                      td {{ session.activity.name }}

                    tr
                      td
                        b {{ $t('report.session.intensity') }}

                      td {{ $t('report.session.intensity' + session.intensity) }}

                    tr
                      td
                        b {{ $t('report.session.garment') }}

                      td {{ session.garment.name }}

                    tr
                      td
                        b {{ $t('report.session.description') }}

                      td {{ session.description }}

              .col-md-6
                h5 {{ $t('report.subject.caption') }}
                table.table.table-sm#data-subject
                  tbody
                    tr
                      td
                        b {{ $t('report.subject.age') }}
                      td {{ session.age }}

                    tr
                      td
                        b {{ $t('report.subject.weight') }}
                      td {{ session.weight }}

                    tr
                      td
                        b {{ $t('report.subject.height') }}
                      td {{ session.height }}

                    tr
                      td
                        b {{ $t('report.subject.gender') }}
                      td {{ $t('report.subject.gender' + session.gender)  }}

                    tr
                      td
                        b {{ $t('report.subject.observations') }}
                      td {{ session.observations }}

        .card
          .h5.card-header {{ $t('group.emg.name') }}
          .card-body
            .row(v-if="isChartEnabled('emgAbsolute')")
              .col-md-12
                h5.card-title {{ $t('group.emg.emg_abs.name') }}
                chart-emg-absolute.mt-5(:report="report")

            .row(class="page" v-if="isChartEnabled('emgPercentage')")
              .col-md-12
                h5.card-title {{ $t('group.emg.emg_pc.name') }}
                chart-emg-percentage.mt-5(:report="report")

      .card
        .h5.card-header {{ $t('group.md.name') }}
        .card-body
          .row
            div(:class="{ 'col-md-4': numberOfSensors > 2, 'col-md-6': numberOfSensors <= 2}" v-if="isChartEnabled('mdBars')")
              h5.card-title {{ $t('group.md.md_bars.name') }}
              chart-md-bars.mt-5(:report="report")

            .col-md-4(v-if="numberOfSensors > 2 && isChartEnabled('mdRadar')")
              h5.card-title {{ $t('group.md.md_radar.name') }}
              chart-md-radar.mt-5( :report="report")

            div(:class="{ 'col-md-4': numberOfSensors > 2, 'col-md-6': numberOfSensors <= 2}" v-if="isChartEnabled('mdSymmetry')")
              h5.card-title {{ $t('group.md.symmetry.name') }}
              chart-md-symmetry.mt-5(:report="report")
</template>

<script>
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import http from "@/http/client";
import ChartEmgAbsolute from "@/components/report/ChartEmgAbsolute";
import ChartEmgPercentage from "@/components/report/ChartEmgPercentage";
import ChartMdBars from "@/components/report/ChartMdBars";
import ChartMdRadar from "@/components/report/ChartMdRadar";
import ChartMdSymmetry from "@/components/report/ChartMdSymmetry";

export default {
  components: {
    ChartEmgAbsolute,
    ChartEmgPercentage,
    ChartMdBars,
    ChartMdRadar,
    ChartMdSymmetry
  },

  props: {
    uuid: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      generating: false,
      report: null,
      series: [],
      images: {}
    }
  },

  methods: {
    load() {
      this.loading = true;
      http
          .get(`/reports/${this.uuid}`)
          .then((response) => this.report = response.data)
          .finally(() => this.loading = false)
    },

    isChartEnabled(codename) {
      if (!this.report) {
        return true;
      }

      return this.report.session.garment.charts[codename].enabled;
    },

    reportText(text = 'text') {
      const session = this.report.session;

      return this.$t('report.' + text, {
        age: session.age,
        height: session.height,
        weight: session.weight,
        gender: session.gender
      });
    },

    download() {
      const pageConfig = { unit: 'px', format: 'a4', orientation: 'portrait' };
      const pdf = new jsPDF(pageConfig);

      const tableWidth = 180;
      const gap        = 20;
      const margin     = 10;
      const top        = 15;

      const width = 430;
      const height = 102;
      const textHeight = 10
      let chartsStart = 180;

      this.generating = true;

      pdf.setFont("helvetica");
      pdf.setFontSize(15);

      pdf.text(this.$t('report.session.caption'), margin, top)

      autoTable(pdf, {
        startY: top + 5,
        margin: margin,
        tableWidth: tableWidth,
        html: '#data-session'
      });

      pdf.text(this.$t('report.subject.caption'), margin + tableWidth + gap, top)

      autoTable(pdf, {
        startY: top + 5,
        margin: margin + tableWidth + gap,
        tableWidth: tableWidth,
        html: '#data-subject'
      });

      this.$apexcharts
        .exec('emgAbsolute', 'dataURI')
        .then(({ imgURI }) => {
          pdf.text(this.$t('group.emg.emg_abs.name'), margin, chartsStart);
          pdf.addImage(imgURI, 'PNG', margin, chartsStart += textHeight, width, height);
          return this.$apexcharts.exec('emgPercentage', 'dataURI');
        })
        .then(({ imgURI }) => {
          pdf.text(this.$t('group.emg.emg_pc.name'), margin, chartsStart += height + gap);
          pdf.addImage(imgURI, 'PNG', margin, chartsStart += textHeight, width, height);
          return this.$apexcharts.exec('mdBars', 'dataURI');
        })
        .then(({ imgURI }) => {
          pdf.text(this.$t('group.md.md_bars.name'), margin, chartsStart += height + gap);
          pdf.addImage(imgURI, 'PNG', margin, chartsStart += textHeight, width / 3, height);
          return this.$apexcharts.exec('mdRadar', 'dataURI');
        })
        .then(({ imgURI }) => {
          pdf.addImage(imgURI, 'PNG', margin + (width / 3), chartsStart += textHeight, width / 3, height);
          return this.$apexcharts.exec('mdSymmetry', 'dataURI');
        })
        .then(({ imgURI }) => {
          pdf.addImage(imgURI, 'PNG', margin + (width / 3)*2, chartsStart += textHeight, width / 3, height - 50);
          pdf.save(`report-${this.report.uuid}`);
        })
        .finally(() => this.generating = false)
      ;
    }
  },

  computed: {
    numberOfSensors() {
      if (!this.report) {
        return 0;
      }

      return this.report.session.garment.sensors.length;
    },
    session() {
      return this.report.session;
    }
  },

  mounted() {
    this.load();
  }
}
</script>

<i18n>
{
  "es": {
    "download": {
      "button": {
        "label": "Descargar informe"
      }
    },
    "report": {
      "session": {
        "caption": "Información sesión",
        "name": "Nombre",
        "date": "Fecha",
        "activity": "Actividad",
        "intensity": "Intensidad",
        "intensityL": "Baja",
        "intensityM": "Media",
        "intensityH": "Alta",
        "garment": "Prenda",
        "description": "Notas"
      },
      "subject": {
        "caption": "Información sujeto",
        "age": "Edad",
        "weight": "Peso (kg)",
        "height": "Altura (cm)",
        "gender": "Sexo",
        "genderM": "Hombre",
        "genderF": "Mujer",
        "genderO": "Otro",
        "observations": "Observaciones"
      }
    },
    "group": {
      "emg": {
        "name": "Electromiografía",
        "emg_abs": {
          "name": "Electromiografía absoluta"
        },
        "emg_pc": {
          "name": "Electromiografia en porcentajes"
        }
      },
      "md": {
        "name": "Distribución actividad muscular",
        "md_bars": {
          "name": "Distribución muscular en porcentajes"
        },
        "md_radar": {
          "name": "Radar de distribución muscular",
          "minSeriesError": "Para mostrar el gráfico de <i>radar de distribución muscular</i> se necesitan al menos <b>tres sensores</b> configurados en la prenda"
        },
        "symmetry": {
          "name": "Índice de simetría muscular"
        }
      }
    }
  }
}
</i18n>
