<script>
import Chart from './Chart';
import _ from 'lodash';

export default {
  mixins: [ Chart ],

  methods: {
    labels() {
      return this.report.session.garment.sensors.map((sensor) => sensor.name);
    }
  },

  computed: {
    mergedOptions() {
      return _.merge(this.baseOptions, {
        chart: {
          id: 'mdRadar',
          type: 'radar',
          height: 350,
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },

        xaxis: {
          categories: this.labels()
        },

        stroke: {
          width: 2
        },

        fill: {
          type: 'solid',
          opacity: 0.2
        }
      });
    },
    series() {
      const data = [];

      this.report.session.garment.sensors.forEach((sensor) => {
        data.push(this.report.view.dist[sensor.uid.toLowerCase()]);
      });

      return [ { data: data } ];
    }
  }
}
</script>

<i18n>
{
  "es": {
    "chart": {
      "xaxis": {
        "distribution": "Distribución"
      }
    }
  }
}
</i18n>
