<script>
import Chart from './Chart';
import _ from 'lodash';

export default {
  mixins: [ Chart ],

  methods: {
    categories() {
      return this.series.map((serie) => serie.name);
    }
  },

  computed: {
    mergedOptions() {
      return _.merge(this.baseOptions, {
        chart: {
          id: 'mdBars',
          type: 'bar',
          height: 350
        },

        plotOptions: {
          bar: {
            horizontal: false,
          }
        },

        yaxis: {
          title: {
            text: this.$t('chart.yaxis.amplitude')
          }
        },

        xaxis: {
          title: {
            text: this.$t('chart.xaxis.distribution')
          },
          categories: this.categories()
        },

        fill: {
          type: 'solid',
          opacity: 0.9
        }
      });
    },
    series() {
      return this.fetchSeriesData('dist');
    }
  }
}
</script>

<i18n>
{
  "es": {
    "chart": {
      "yaxis": {
        "amplitude": "Amplitud (%)"
      },
      "xaxis": {
        "distribution": "Distribución"
      }
    }
  }
}
</i18n>
