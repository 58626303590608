<script>
import Chart from './Chart';
import _ from 'lodash';

export default {
  mixins: [ Chart ],

  computed: {
    mergedOptions() {
      return _.merge(this.baseOptions, {
        "chart": {
          "type": "area",
          "stacked": false,
          id: 'emgPercentage',
          group: 'emg',
          "zoom": {
            "type": "x",
            "enabled": true,
            "autoScaleYaxis": true
          },
          "toolbar": {
            "autoSelected": "zoom"
          }
        },

        "yaxis": {
          "title": {
            "text": "%"
          },
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            },
          },
          tickAmount: 10
        },

        xaxis: {
          title: {
            text: this.$t('chart.xaxis.time')
          },
          type: 'numeric',
          categories: _.range(0, this.report.duration, 1)
        }
      });
    },
    series() {
      return this.fetchSeriesData('percent');
    }
  }
}
</script>

<i18n>
{
  "es": {
    "chart": {
      "xaxis": {
        "time": "Tiempo"
      }
    }
  }
}
</i18n>
