<script>
import Chart from './Chart';
import _ from 'lodash';

export default {
  mixins: [ Chart ],

  methods: {
    labels() {
      return this.report.session.garment.sensors.map((sensor) => sensor.name);
    }
  },

  computed: {
    mergedOptions() {
      return _.merge(this.baseOptions, {
        chart: {
          id: 'mdSymmetry',
          type: 'donut',
          height: 350,
        },

        labels: this.labels(),

        dataLabels: {
          enabled: true
        },

        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10
          }
        },

        grid: {
          padding: {
            bottom: -80
          }
        },

        fill: {
          type: 'solid',
          opacity: 0.9
        }

      });
    },
    series() {
      const data = [];

      this.report.session.garment.sensors.forEach((sensor) => {
        data.push(this.report.view.dist[sensor.uid.toLowerCase()]);
      });

      return data;
    }
  }
}
</script>

<i18n>
{
  "es": {
    "chart": {
      "xaxis": {
        "distribution": "Distribución"
      }
    }
  }
}
</i18n>
